export type RoogContract = {
  version: "0.1.0";
  name: "roog_contract";
  instructions: [
    {
      name: "initialize";
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "newAuthority";
          type: "publicKey";
        }
      ];
    },
    {
      name: "buyRoogs";
      accounts: [
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "account";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "sellRoogs";
      accounts: [
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "account";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "hatchRoogs";
      accounts: [
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "referral";
          isMut: false;
          isSigner: false;
        },
        {
          name: "referralState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    }
  ];
  accounts: [
    {
      name: "globalState";
      type: {
        kind: "struct";
        fields: [
          {
            name: "isInitialized";
            type: "u8";
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "vault";
            type: "publicKey";
          },
          {
            name: "treasury";
            type: "publicKey";
          },
          {
            name: "marketRoogs";
            type: "u64";
          },
          {
            name: "devFee";
            type: "u64";
          },
          {
            name: "psn";
            type: "u64";
          },
          {
            name: "psnh";
            type: "u64";
          },
          {
            name: "roogsPerMiner";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "userState";
      type: {
        kind: "struct";
        fields: [
          {
            name: "isInitialized";
            type: "u8";
          },
          {
            name: "user";
            type: "publicKey";
          },
          {
            name: "lastHatchTime";
            type: "u64";
          },
          {
            name: "claimedRoogs";
            type: "u64";
          },
          {
            name: "miners";
            type: "u64";
          },
          {
            name: "referral";
            type: "publicKey";
          },
          {
            name: "referralSet";
            type: "u8";
          }
        ];
      };
    }
  ];
  errors: [
    {
      code: 6000;
      name: "NotAllowedAuthority";
      msg: "Not allowed authority";
    },
    {
      code: 6001;
      name: "InsufficientAmount";
      msg: "Should be over minimum amount";
    },
    {
      code: 6002;
      name: "IncorrectUserState";
      msg: "Incorrect User State";
    },
    {
      code: 6003;
      name: "IncorrectReferral";
      msg: "Incorrect Referral Pubkey";
    },
    {
      code: 6004;
      name: "IncorrectTokenAddress";
      msg: "Incorrect Token Address";
    }
  ];
};

export const IDL: RoogContract = {
  version: "0.1.0",
  name: "roog_contract",
  instructions: [
    {
      name: "initialize",
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "newAuthority",
          type: "publicKey",
        },
      ],
    },
    {
      name: "buyRoogs",
      accounts: [
        {
          name: "user",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "account",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
      ],
    },
    {
      name: "sellRoogs",
      accounts: [
        {
          name: "user",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "account",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "hatchRoogs",
      accounts: [
        {
          name: "user",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "referral",
          isMut: false,
          isSigner: false,
        },
        {
          name: "referralState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "globalState",
      type: {
        kind: "struct",
        fields: [
          {
            name: "isInitialized",
            type: "u8",
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "vault",
            type: "publicKey",
          },
          {
            name: "treasury",
            type: "publicKey",
          },
          {
            name: "marketRoogs",
            type: "u64",
          },
          {
            name: "devFee",
            type: "u64",
          },
          {
            name: "psn",
            type: "u64",
          },
          {
            name: "psnh",
            type: "u64",
          },
          {
            name: "roogsPerMiner",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "userState",
      type: {
        kind: "struct",
        fields: [
          {
            name: "isInitialized",
            type: "u8",
          },
          {
            name: "user",
            type: "publicKey",
          },
          {
            name: "lastHatchTime",
            type: "u64",
          },
          {
            name: "claimedRoogs",
            type: "u64",
          },
          {
            name: "miners",
            type: "u64",
          },
          {
            name: "referral",
            type: "publicKey",
          },
          {
            name: "referralSet",
            type: "u8",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "NotAllowedAuthority",
      msg: "Not allowed authority",
    },
    {
      code: 6001,
      name: "InsufficientAmount",
      msg: "Should be over minimum amount",
    },
    {
      code: 6002,
      name: "IncorrectUserState",
      msg: "Incorrect User State",
    },
    {
      code: 6003,
      name: "IncorrectReferral",
      msg: "Incorrect Referral Pubkey",
    },
    {
      code: 6004,
      name: "IncorrectTokenAddress",
      msg: "Incorrect Token Address",
    },
  ],
};
